.Hike {
}
.Hike--title {
	position: relative;
	height: 80vh;
	z-index: 0;
	background: #C3D494;
	display: flex;
	flex-direction: column;
	color: white;
	justify-content: flex-end;
	line-height: 1;
	padding: 40px;

	color: white;
	font-size: 20px;
	font-weight: 800;

	h1 {
		font-size: 10vw;
		margin-right: 300px;
	}
}


.Hike--pin {
	$size: 200px;
	position: absolute;
	width: $size;
	height: $size;
	top: 100%;
	margin-top: - $size * 0.75;
	right: 40px;

	border-radius: 100%;
	overflow: hidden;
	box-shadow: 0 3px 5px rgba(0,0,10, 0.5);
	> img {
		display: block;
		width: 100%; height: 100%;
	}
}



.Hike--photo {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;
	z-index: -1;

	&:before {
		content: '';
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0) 60%);;
		z-index: 1;
	}

	> img {
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.Hike--content {
	padding: 40px;
	margin: 0 auto;
	max-width: 1000px;
}

.Hike--map {
	$radius: 32px;
	$stroke: 3px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: calc(50% - #{$radius});
		left: calc(50% - #{$radius});
		width: 2 * ($radius - $stroke);
		height: 2 * ($radius - $stroke);
		border-radius: $radius;
		border: $stroke solid rgba(#222, 0.8);
		background: rgba(white, 0.2);
		box-shadow: 0 2px 5px rgba(black, 0.4);
		z-index: 1;
	}

	> img {
		display: block;
		width: 100%;
	}
}
.Hike--participants {
	> h2 {
		font-size: 20px;
	}

	.Participant--photo {
		font-size: 60px;
	}

	.Participant--name {
		font-size: inherit;
	}
}

.Hike--photos {

	&:not(.is-layouted) {
		display: flex;
		flex-wrap: wrap;
	}

	&.is-layouted {
		> .row {
			display: flex;

			> * {
				flex-grow: 1;
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}


	img {
		height: 200px;
		display: block;

		@media (max-width: 1000px) {
			height: 150px;
		}

		@media (max-width: 600px) {
			height: 100px;
		}
	}
}
