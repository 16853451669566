.richtext {
	p {
		margin: 1em auto;
	}

	a {
		color: #009D0B;
		text-decoration: underline;
		font-weight: bold;
	}
}
