.Participants {
	display: flex;

	> * {
		margin-right: 5px;
	}
}

.Participant {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	&.organisator {
		.Participant--photo {
			border-color: black;
		}
		.Participant--name {
			background: black;
			color: white;
		}
	}
}

.Participant--name {
	margin-top: 1px;
	font-size: 10px;
	text-align: center;
	order: 2;
	padding: 2px 4px;
	border-radius: 1em;
}

.Participant--photo {
	order: 1;
	font-size: 36px;
	width: 1em;
	height: 1em;
	border-radius: 1em;
	border: 2px solid white;
	overflow: hidden;

	> img {
		display: block;
		width: 100%;
	}
}
