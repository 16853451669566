@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  border: 0;
  list-style: none;
  text-decoration: inherit; }

#flying-focus {
  position: absolute;
  margin: 0;
  top: -100px;
  background: transparent;
  transition-property: left, top, width, height, opacity;
  transition-timing-function: cubic-bezier(0, 0.2, 0, 1);
  visibility: hidden;
  pointer-events: none;
  display: none; }
  #flying-focus > svg {
    position: absolute;
    top: -9px;
    left: -9px;
    width: calc(100% + 18px);
    height: calc(100% + 18px);
    filter: blur(1px); }
    #flying-focus > svg > rect {
      stroke-width: 10;
      stroke: #1E343C;
      stroke-linecap: butt;
      stroke-dasharray: 5 9;
      animation: stroke-anim 0.5s infinite linear;
      fill: none; }

@keyframes stroke-anim {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 14; } }

#flying-focus {
  visibility: visible;
  z-index: 10000000;
  display: block; }

#flying-focus:before {
  content: '';
  position: absolute;
  top: -7px;
  left: -7px;
  bottom: -7px;
  right: -7px;
  border-radius: 3px;
  box-shadow: 0 0 15px 2px rgba(255, 255, 255, 0.9) inset, 0 0 15px 2px rgba(255, 255, 255, 0.9); }

.Hike--title {
  position: relative;
  height: 80vh;
  z-index: 0;
  background: #C3D494;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: flex-end;
  line-height: 1;
  padding: 40px;
  color: white;
  font-size: 20px;
  font-weight: 800; }
  .Hike--title h1 {
    font-size: 10vw;
    margin-right: 300px; }

.Hike--pin {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 100%;
  margin-top: -150px;
  right: 40px;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(0, 0, 10, 0.5); }
  .Hike--pin > img {
    display: block;
    width: 100%;
    height: 100%; }

.Hike--photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  .Hike--photo:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0) 60%);
    z-index: 1; }
  .Hike--photo > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.Hike--content {
  padding: 40px;
  margin: 0 auto;
  max-width: 1000px; }

.Hike--map {
  position: relative; }
  .Hike--map:after {
    content: '';
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 58px;
    height: 58px;
    border-radius: 32px;
    border: 3px solid rgba(34, 34, 34, 0.8);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    z-index: 1; }
  .Hike--map > img {
    display: block;
    width: 100%; }

.Hike--participants > h2 {
  font-size: 20px; }

.Hike--participants .Participant--photo {
  font-size: 60px; }

.Hike--participants .Participant--name {
  font-size: inherit; }

.Hike--photos:not(.is-layouted) {
  display: flex;
  flex-wrap: wrap; }

.Hike--photos.is-layouted > .row {
  display: flex; }
  .Hike--photos.is-layouted > .row > * {
    flex-grow: 1; }
  .Hike--photos.is-layouted > .row img {
    width: 100%;
    height: auto; }

.Hike--photos img {
  height: 200px;
  display: block; }
  @media (max-width: 1000px) {
    .Hike--photos img {
      height: 150px; } }
  @media (max-width: 600px) {
    .Hike--photos img {
      height: 100px; } }

.HikeTeaserList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -2px; }
  .HikeTeaserList > * {
    flex: 0 1 50%;
    position: relative;
    padding-top: 25%; }
  .HikeTeaserList .HikeTeaser {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 2px;
    left: 2px; }

.HikeTeaser {
  position: relative;
  z-index: 0;
  background: #C3D494;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: flex-end;
  line-height: 1;
  padding: 20px; }
  .HikeTeaser .Participants {
    margin-top: 10px; }
    @media (max-width: 700px) {
      .HikeTeaser .Participants {
        position: absolute;
        top: 0;
        left: 10px; } }
  .HikeTeaser .Participant--photo {
    font-size: 24px; }
  @media (max-width: 700px) {
    .HikeTeaser {
      padding: 10px; }
      .HikeTeaser .Participant {
        margin-right: -5px; }
      .HikeTeaser .Participant--name {
        display: none; } }

.HikeTeaser--photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  .HikeTeaser--photo:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0) 60%);
    z-index: 1; }
  .HikeTeaser--photo > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.HikeTeaser--pin {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(0, 0, 10, 0.5); }
  .HikeTeaser--pin > img {
    display: block;
    width: 70px;
    height: 70px;
    margin: -10px; }

.HikeTeaser--title {
  font-size: 20px;
  font-weight: 800; }
  @media (max-width: 700px) {
    .HikeTeaser--title {
      font-size: 14px; } }

.HikeTeaser--year {
  font-size: 40px;
  font-weight: 800; }
  @media (max-width: 700px) {
    .HikeTeaser--year {
      font-size: 24px; } }

.HikeTooltip {
  position: relative;
  z-index: 0;
  background: #C3D494;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: flex-end;
  line-height: 1;
  padding: 20px;
  width: 300px;
  height: 200px; }
  .HikeTooltip.summer .HikeTooltip--title:before {
    content: '☀️'; }
  .HikeTooltip.winter .HikeTooltip--title:before {
    content: '❄️'; }
  .HikeTooltip .Participant--photo {
    display: none; }

.HikeTooltip--photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  .HikeTooltip--photo:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 60%);
    z-index: 1; }
  .HikeTooltip--photo > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.HikeTooltip--season {
  display: none; }

.HikeTooltip--title {
  font-size: 20px;
  font-weight: 800; }

.HikeTooltip--year {
  font-size: 40px;
  font-weight: 800; }

.Map {
  height: 100vh; }
  .Map > .HikeTooltip {
    display: none; }

.mapboxgl-popup-content {
  padding: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3); }

.Participants {
  display: flex; }
  .Participants > * {
    margin-right: 5px; }

.Participant {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; }
  .Participant.organisator .Participant--photo {
    border-color: black; }
  .Participant.organisator .Participant--name {
    background: black;
    color: white; }

.Participant--name {
  margin-top: 1px;
  font-size: 10px;
  text-align: center;
  order: 2;
  padding: 2px 4px;
  border-radius: 1em; }

.Participant--photo {
  order: 1;
  font-size: 36px;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  border: 2px solid white;
  overflow: hidden; }
  .Participant--photo > img {
    display: block;
    width: 100%; }

.richtext p {
  margin: 1em auto; }

.richtext a {
  color: #009D0B;
  text-decoration: underline;
  font-weight: bold; }

html {
  font-family: 'Helvetica Neue LT', 'Helvetica Neue', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400; }

h1 {
  font-size: 100%;
  font-weight: 800; }

h2 {
  font-size: 20px;
  font-weight: 800;
  margin-top: 1em;
  margin-bottom: 10px; }

button {
  cursor: pointer; }

*:focus {
  outline: none; }

.PageTitle.overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  font-size: inherit;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  padding: 5px;
  line-height: 1; }

.MoveDownButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  font-size: inherit;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  padding: 5px;
  line-height: 1; }
