@import "/opt/build/repo/source/assets/css/_reset.scss";
@import "/opt/build/repo/source/assets/css/_settings.scss";

@import "/opt/build/repo/source/assets/css/mixins/_animation.scss";
@import "/opt/build/repo/source/assets/css/mixins/_font-weight.scss";
@import "/opt/build/repo/source/assets/css/mixins/_mediaqueries.scss";

@import "/opt/build/repo/source/assets/css/components/_flyingfocus.scss";
@import "/opt/build/repo/source/assets/css/components/_Hike.scss";
@import "/opt/build/repo/source/assets/css/components/_HikeTeaser.scss";
@import "/opt/build/repo/source/assets/css/components/_HikeTooltip.scss";
@import "/opt/build/repo/source/assets/css/components/_Map.scss";
@import "/opt/build/repo/source/assets/css/components/_mapbox.scss";
@import "/opt/build/repo/source/assets/css/components/_Participants.scss";
@import "/opt/build/repo/source/assets/css/components/_richtext.scss";


html {
	font-family: 'Helvetica Neue LT', 'Helvetica Neue', 'Helvetica', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-weight: 400;
}

body {
}

h1 {
	font-size: 100%;
	font-weight: 800;
}
h2 {
	font-size: 20px;
	font-weight: 800;
	margin-top: 1em;
	margin-bottom: 10px;
}

button {
	cursor: pointer;
}

*:focus {
	outline: none;
}

.PageTitle.overlay {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 2;
	font-size: inherit;
	background: rgba(white, 0.5);
	border-radius: 20px;
	padding: 5px;
	line-height: 1;
}

.MoveDownButton {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 2;
	font-size: inherit;
	background: rgba(white, 0.5);
	border-radius: 20px;
	padding: 5px;
	line-height: 1;
}
