.HikeTeaserList {
	$gap: 2px;


	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: -$gap;

	> * {
		flex: 0 1 50%;
		position: relative;
		padding-top: 25%;
	}

	.HikeTeaser {
		position: absolute;
		top: 0; right: 0; bottom: $gap; left: $gap;
	}
}


.HikeTeaser {
	position: relative;
	z-index: 0;
	background: #C3D494;
	display: flex;
	flex-direction: column;
	color: white;
	justify-content: flex-end;
	line-height: 1;
	padding: 20px;

	.Participants {
		margin-top: 10px;

		@include mobile {
			position: absolute;
			top: 0;
			left: 10px;
		}
	}
	.Participant--photo {
		font-size: 24px;
	}
	@include mobile {
		padding: 10px;

		.Participant {
			margin-right: -5px;
		}
		.Participant--name {
			display: none;
		}
	}
}

.HikeTeaser--photo {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;
	z-index: -1;

	&:before {
		content: '';
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0) 60%);;
		z-index: 1;
	}

	> img {
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		object-fit: cover;
		object-position: center;
	}

}

.HikeTeaser--pin {
	position: absolute;
	top: 10px; right: 10px;
	border-radius: 100%;
	overflow: hidden;
	box-shadow: 0 3px 5px rgba(0,0,10, 0.5);
	> img {
		display: block;
		width: 70px; height: 70px;
		margin: -10px;
	}
}
.HikeTeaser--title {
	font-size: 20px;
	font-weight: 800;

	@include mobile {
		font-size: 14px;
	}
}
.HikeTeaser--year {
	font-size: 40px;
	font-weight: 800;

	@include mobile {
		font-size: 24px;
	}
}


