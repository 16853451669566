
.HikeTooltip {
	position: relative;
	z-index: 0;
	background: #C3D494;
	display: flex;
	flex-direction: column;
	color: white;
	justify-content: flex-end;
	line-height: 1;
	padding: 20px;
	width: 300px;
	height: 200px;


	& .HikeTooltip--title:before {

	}

	&.summer .HikeTooltip--title:before {
		content: '☀️';
	}

	&.winter .HikeTooltip--title:before {
		content: '❄️';
	}

	.Participant--photo {
		display: none;
	}
}

.HikeTooltip--photo {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;
	z-index: -1;

	&:before {
		content: '';
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 60%);;
		z-index: 1;
	}

	> img {
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.HikeTooltip--season {
	display: none;
}
.HikeTooltip--title {
	font-size: 20px;
	font-weight: 800;
}
.HikeTooltip--year {
	font-size: 40px;
	font-weight: 800;
}
